.App {
  text-align: left;
}

html, body, #app, #app>div {
	height: 100%
  }

.appbody {
	background-color:#CCC;
	text-align: center;
	min-height: 100%;
	margin: 0;
	padding: 0;
	min-width: 900px;
}

.appcontainer {
	padding: 8px;
	text-align: left;
	margin:auto;
	max-width: 1280px;
	background-color:#CCC;
	min-width: 900px;
}

a {
	text-decoration: none;
	color: #4a80b6;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #111;
  padding-bottom: 30px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




.tablestyle {
	width: 100%;
	table-layout: auto;
	border: 2px solid #BBB;
	border-collapse: collapse;
	border-spacing: 0;
  }
  
  .tablestyle thead tr td,
  .tablestyle tfoot tr td,
  .tablestyle tbody tr td,
  .tablestyle tr td,
  .tablestyle tbody tr {
	border: 1px solid #F9F9F9;
	border-spacing: 0;
	padding: 3px;
  }
  
  .tablestyle tbody tr td,
  .tablestyle tr td,
  .tablestyle tbody tr {
	border: 1px solid #CCC;
	border-spacing: 0;
	padding: 3px;
  }

.tableheaderstyle td {
	padding:6px;
	border-bottom: 2px solid #D4D4D4;
	border-top: 2px solid #D4D4D4;
	background-color: #EAEAEA;
	font-weight: bold;
	text-align: left;
}